import React, { Component, Fragment } from 'react'
// 路由组件引入about一般组件
import About from '../../components/About'

export default class AboutRouter extends Component {
  render() {
    return (
      <Fragment>
        <About />
      </Fragment>
    )
  }
}
