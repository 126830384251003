import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'


import './Footer.css'
import wx from '../../assets/weixin.jpg'

class Footer extends Component {
    render() {
        let foolist1 =[
            {id:0,name:'网站导航',con1:'首页',con2:'产品',con3:'关于我们',con4:'我的应用'},
            {id:1,name:'友情链接',con1:'首页',con2:'普联软件股份有限公司',con3:'运维社区',con4:'票联易后台'},
            {id:2,name:'联系我们',con1:'邮编：250100',con2:'邮箱：xiacongcong@pansoft.com',con3:'行政：0531-88871161（行政办公室）',con4:'地址：山东济南市高新区齐鲁软件园创业广场D座5楼'},
        ]
        return (
            <div className='home-footer'>
                <div className='wid'>
                    {
                        foolist1.map((item,index)=>{
                            return (
                                <div key={index} className='foolist1'>
                                    <p>{item.name}</p>
                                    <p onClick={()=>{this.goLink(item.con1)}}>{item.con1}</p>
                                    <p onClick={()=>{this.goLink(item.con2)}}>{item.con2}</p>
                                    <p onClick={()=>{this.goLink(item.con3)}}>{item.con3}</p>
                                    <p onClick={()=>{this.goLink(item.con4)}}>{item.con4}</p>
                                </div>
                            )
                        })
                    }
                    <div className='wx'>
                        <img src={wx} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
    goLink=(params)=> {
        // console.log(params)
        if(params === '首页'){
            this.props.history.push('/homepage')
        }else if(params === '关于我们'){
            this.props.history.push('/about')
        }else if(params === '我的应用'){
            this.props.history.push('/application')
        }else if(params === '票联易后台'){
            window.open('https://smart.pansoft.com:8085/appfpocr/login/index.html')
        }
    }
}

export default withRouter(Footer)
