import React, { Component } from 'react'
import One from './images/4028aa877978ee2a01798dd1157505d0.jpg'
import Two from './images/4028aa877978ee2a01798e2f506f06b9.png'
import './css/index.css'
import './icon/icon.css'
import './css/index.css'
import './css/def.css'
import './css/timeline.css'

export default class About extends Component {
  state = {
    company: {
      title: '公司介绍',
       msgOne: '普联软件股份有限公司是国家规划布局内重点软件企业，国家认定的高新技术企业，科技部认定的国家火炬计划软件产业基地骨干企业，山东省企业移动计算工程技术研究中心和山东省软件工程技术中心依托企业。公司总部位于济南高新区齐鲁软件园，在北京、深圳、成都、西安、武汉、合肥、乌鲁木齐、库尔勒、香港、日本大阪等地设有区域机构。',
      msgTwo: '普联软件是大型企业管理信息化方案及IT综合服务提供商，主要面向石油、化工、建筑、地产、电力、矿产、装备制造、银行、保险、政府事业等行业客户，广泛从事生产、经营、财务、资金、内控、监管、人力资源等领域的信息化服务，提供信息系统的方案设计、软件开发、系统实施及技术支持。',
      msgThree: '普联软件拥有包括中国石油、中国石化、中国海油、中国建筑、中国大唐、中国广核、中化集团、中国葛洲坝集团等大型央企，万达集团、龙湖集团、潍柴动力、紫金矿业等行业龙头企业，浦发银行、农业银行、招商银行、太平洋保险、中国人寿等金融保险机构，以及财政部、银保监会等政府部门在内的高端客户群，是中国500强中多家企业的信息化核心合作伙伴。'
    },
    product: {
      title: '票联易产品介绍',
      msgOne: '作为国内外重点软件企业，普联软件完善的产品体系能够多方位满足市场需求，遍布各大中心城市的售后服务点能在第一时间响应客户需求。',
       msgTwo: '在提供优质的产品和服务的基础上，普联软件将持续推动中国商业影像市场的发展，实现用户、合作伙伴的共赢！'
    },
    productTwo: {
      one: {
        title: 'OCR识别产品优势',
        msg: {
            first: '票种识别',
            second: '票面内容识别',
            three: '识别方式'
         }
      }
    },
    develop: {
      title: '发展历程'
    }
  }
  componentDidMount () {
    console.log('加载完成')
    let timeline_block = document.querySelectorAll('.cd-timeline-block')
		// console.log(document.documentElement.clientHeight)
    timeline_block.forEach(item => {
			// console.log(item.offsetTop)
      if (item.offsetTop + document.documentElement.clientHeight*2.4 > document.documentElement.scrollTop ) {
        item.querySelectorAll('.cd-timeline-img, .cd-timeline-content').forEach(val => {
          val.classList.add('is-hidden')
        })
      }
    })
    window.addEventListener("scroll", function () {
      timeline_block.forEach(item => {
        if (item.offsetTop + document.documentElement.clientHeight*2.4 <= document.documentElement.scrollTop ) {
          item.querySelectorAll('.cd-timeline-img, .cd-timeline-content').forEach(val => {
            if (val.getAttribute('class').indexOf('is-hidden') > -1) {
							console.log('bounce-in')
              val.classList.remove('is-hidden')
              val.classList.add('bounce-in')
            }
          })
        }
      })
    })
  }
  render() {
    return (
      <div className="customlayout-xroot comp-root custom-layout-relative main s-101104-0"
		 idn-101104="" style={{ width: '100%' }}>
			<div className="customlayout-xroot comp-root custom-layout-relative compant-about s-101104-1">
				<div className="customlayout-xroot comp-root custom-layout-relative about-company-text s-101104-2">
					<div className="customlayout-xroot comp-root custom-layout-relative about-company-bg s-101104-3"
					bgimg="true">
            {this.state.company.title}
					</div>
					<div className="customlayout-xroot comp-root custom-layout-relative about-company-note s-101104-4"
					>
            {this.state.company.msgOne}
					</div>
					<div className="customlayout-xroot comp-root custom-layout-relative about-company-note s-101104-5"
					>
            {this.state.company.msgTwo}
					</div>
					<div className="customlayout-xroot comp-root custom-layout-relative about-company-note s-101104-6"
					>
            {this.state.company.msgThree}
					</div>
				</div>
			</div>
			<div className="customlayout-xroot comp-root custom-layout-relative about-product s-101104-7">
				<div className="customlayout-xroot comp-root custom-layout-relative about-product-main s-101104-8">
					<div className="customlayout-xroot comp-root custom-layout-relative about-product-bg s-101104-9"
					bgimg="true">
            {this.state.product.title}
					</div>
					<div className="customlayout-xroot comp-root custom-layout-relative about-product-note s-101104-10">
						<div className="customlayout-xroot comp-root custom-layout-relative about-product-img-note s-101104-11">
							<div className="customlayout-xroot comp-root custom-layout-relative about-product-img-text s-101104-12">
								<div className="customlayout-xroot comp-root custom-layout-relative about-product-text s-101104-13"
								v-text="mypro.about.product.msgOne">
                  {this.state.product.msgOne}
								</div>
								<div className="customlayout-xroot comp-root custom-layout-relative about-product-text s-101104-14"
								v-text="mypro.about.product.msgTwo">
                  {this.state.product.msgTwo}
								</div>
							</div>
						</div>
						<div className="customlayout-xroot comp-root custom-layout-relative about-product-img s-101104-15">
							<div className="image-xroot comp-root s-101104-16">
								{/* <img alt='img' className="image-content image-empty" src="./images/4028aa877978ee2a01798dd1157505d0.jpg" /> */}
								<img alt='img' className="image-content image-empty" src={One} />
							</div>
						</div>
					</div>
					<div className="customlayout-xroot comp-root custom-layout-relative about-product-note s-101104-17">
						<div className="customlayout-xroot comp-root custom-layout-relative about-product-img s-101104-18">
							<div className="customlayout-xroot comp-root custom-layout-relative s-101104-19"
							_v-align="true">
								<div className="image-xroot comp-root">
									{/* <img alt='img' className="image-content image-empty" src="images/4028aa877978ee2a01798e2f506f06b9.png" /> */}
									<img alt='img' className="image-content image-empty" src={Two} />
								</div>
							</div>
						</div>
						<div className="customlayout-xroot comp-root custom-layout-relative about-product-img-note s-101104-20">
							<div className="customlayout-xroot comp-root custom-layout-relative about-product-img-text s-101104-21">
								<div className="customlayout-xroot comp-root custom-layout-relative s-101104-22">
									<span l="0" className="textshow-xroot comp-root textshow-singlerow s-101104-23">
										OCR识别产品优势
									</span>
									<div className="customlayout-xroot comp-root custom-layout-relative s-101104-24">
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-25">
											票种识别
										</span>
										<div className="customlayout-xroot comp-root custom-layout-absolute s-101104-26">
											<div className="icon-xroot comp-root s-101104-27">
												<span className="smart-icon icon-entity smart-icon-f10c s-101104-28" _h-align="true"
												_v-align="true">
												</span>
											</div>
										</div>
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-29">
											增值税专用发票、增值税普通发票等
										</span>
									</div>
									<div className="customlayout-xroot comp-root custom-layout-relative s-101104-30">
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-31">
											票面内容识别
										</span>
										<div className="customlayout-xroot comp-root custom-layout-absolute s-101104-32">
											<div className="icon-xroot comp-root s-101104-33">
												<span className="smart-icon icon-entity smart-icon-f10c s-101104-34" _h-align="true"
												_v-align="true">
												</span>
											</div>
										</div>
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-35">
											增票通过识别核心信息，获取结构化数据
										</span>
									</div>
									<div className="customlayout-xroot comp-root custom-layout-relative s-101104-36">
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-37">
											识别方式
										</span>
										<div className="customlayout-xroot comp-root custom-layout-absolute s-101104-38">
											<div className="icon-xroot comp-root s-101104-39">
												<span className="smart-icon icon-entity smart-icon-f10c s-101104-40" _h-align="true"
												_v-align="true">
												</span>
											</div>
										</div>
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-41">
											支持手机，扫描仪，高拍仪
										</span>
									</div>
								</div>
								<div className="customlayout-xroot comp-root custom-layout-relative s-101104-42">
									<span l="0" className="textshow-xroot comp-root textshow-singlerow s-101104-43">
										OCR识别能力
									</span>
									<div className="customlayout-xroot comp-root custom-layout-relative s-101104-44">
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-45">
											图像识别
										</span>
										<div className="customlayout-xroot comp-root custom-layout-absolute s-101104-46">
											<div className="icon-xroot comp-root s-101104-47">
												<span className="smart-icon icon-entity smart-icon-f10c s-101104-48" _h-align="true"
												_v-align="true">
												</span>
											</div>
										</div>
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-49">
											基于深度学习的图像识别，打破基于模板的图像识别
										</span>
									</div>
									<div className="customlayout-xroot comp-root custom-layout-relative s-101104-50">
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-51">
											准确率高
										</span>
										<div className="customlayout-xroot comp-root custom-layout-absolute s-101104-52">
											<div className="icon-xroot comp-root s-101104-53">
												<span className="smart-icon icon-entity smart-icon-f10c s-101104-54" _h-align="true"
												_v-align="true">
												</span>
											</div>
										</div>
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-55">
											采用RNN+CNN,准确率高打破传统RNN
										</span>
									</div>
									<div className="customlayout-xroot comp-root custom-layout-relative s-101104-56">
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-57">
											全票面信息
										</span>
										<div className="customlayout-xroot comp-root custom-layout-absolute s-101104-58">
											<div className="icon-xroot comp-root s-101104-59">
												<span className="smart-icon icon-entity smart-icon-f10c s-101104-60" _h-align="true"
												_v-align="true">
												</span>
											</div>
										</div>
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-61">
											打破传统发票无法识别明细局限
										</span>
									</div>
									<div className="customlayout-xroot comp-root custom-layout-relative s-101104-62">
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-63">
											识别方式
										</span>
										<div className="customlayout-xroot comp-root custom-layout-absolute s-101104-64">
											<div className="icon-xroot comp-root s-101104-65">
												<span className="smart-icon icon-entity smart-icon-f10c s-101104-66" _h-align="true"
												_v-align="true">
												</span>
											</div>
										</div>
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-67">
											支持手机，扫描仪，高拍仪
										</span>
									</div>
								</div>
								<div className="customlayout-xroot comp-root custom-layout-relative s-101104-68">
									<span l="0" className="textshow-xroot comp-root textshow-singlerow s-101104-69">
										OCR识别-混扫发票
									</span>
									<div className="customlayout-xroot comp-root custom-layout-relative s-101104-70">
										<span l="2" className="textshow-xroot comp-root textshow-autocontent s-101104-71">
											支持单张扫描、多张扫描、混合扫描
										</span>
										<div className="customlayout-xroot comp-root custom-layout-absolute s-101104-72">
											<div className="icon-xroot comp-root s-101104-73">
												<span className="smart-icon icon-entity smart-icon-f10c s-101104-74" _h-align="true"
												_v-align="true">
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="customlayout-xroot comp-root custom-layout-relative about-develop s-101104-75">
				<div className="customlayout-xroot comp-root custom-layout-relative about-develop-main s-101104-76">
					<div className="customlayout-xroot comp-root custom-layout-relative about-develop-bg s-101104-77"
					bgimg="true">
            {this.state.develop.title}
					</div>
					<div className="develop">
						<section id="cd-timeline" className="cd-container">
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-picture">
									{/* <!-- <img src="images/cd-icon-picture.svg" alt="Picture"> --> */}
								</div>
                {/* <!-- cd-timeline-img --> */}
					
								<div className="cd-timeline-content">
									<h2>2020年</h2>
									<p>拓展战略客户-国家管网；完成中国石化资金管理系统全面提升全球资金管理系统产品成为大型集团企业境外资金管理的标配。</p>
									{/* <!-- <a href="#" className="cd-read-more">阅读更多</a> --> */}
									{/* <!-- <span className="cd-date">2020</span> --> */}
								</div>
							</div>
					
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-movie">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2019年</h2>
									<p>拓展战略客户-中国海油；荣获"中国石油司库建设最佳合作单位"共享服务方案在地产行业形成竞争优势。</p>
								</div>
							</div>
					
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-picture">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2018年</h2>
									<p>拓展战略客户-中国建筑全球资金管理系统方案进入推广阶段；成立建筑事业部。</p>
								</div>
							</div>
					
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-location">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2017年</h2>
									<p>深度参与财政部管理会计专项课题研究；承接新三板披露数据标准化项目。</p>
								</div>
							</div>
					
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-location">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2016年</h2>
									<p>XBRL系列产品在在保险业广泛应用。</p>
								</div>
							</div>
					
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-movie">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2015年</h2>
									<p>共享服务方案进入推广阶段；成为"国家规划布局内重点软件企业"；通过"山东省软件工程技术中心"认定成立共享服务事业部。</p>
								</div>
							</div>
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-movie">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2014年</h2>
									<p>完成股改；设立成都分公司、设立巴州分公司通过"山东省企业移动计算工程技术研究中心"认定。</p>
								</div>
							</div>
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-movie">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2013年</h2>
									<p>荣获"中国能源行业信息化最具影响力企业奖"开始XBRL业务研究与产品研发；完成私有化；设立新疆分公司，成立XBRL事业部。</p>
								</div>
							</div>
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-movie">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2012年</h2>
									<p>通过"国家火炬计划软件产业基地骨干企业"认定。</p>
								</div>
							</div>
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-movie">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2010年</h2>
									<p>中国石化资金管理系统全面应用推出财务共享服务解决方案；设立深圳分公司。</p>
								</div>
							</div>
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-movie">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2009年</h2>
									<p>中国石油ERP与FMIS融合平台上线交付。</p>
								</div>
							</div>
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-movie">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2008年</h2>
									<p>纳斯达克主板上市；设立普联香港公司。</p>
								</div>
							</div>
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-movie">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2007年</h2>
									<p>中国石油会计一级集中核算系统上线交付。</p>
								</div>
							</div>
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-movie">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2002年</h2>
									<p>开始为中国石油提供服务。</p>
								</div>
							</div>
							<div className="cd-timeline-block">
								<div className="cd-timeline-img cd-movie">
								</div>
					
								<div className="cd-timeline-content">
									<h2>2001年</h2>
									<p>公司成立；开始为中国石化提供服务。</p>
								</div>
							</div>
						</section>				
					</div>
				</div>
			</div>
		</div>
    )
  }
}
