import React, { Component } from 'react'
import Mid from '../../components/Mid/Mid'
import Brand from '../../components/Brand/Brand'
import Fun from '../../components/Fun/Fun'
import Pro from '../../components/Pro/Pro'

export default class HomePage extends Component {
    render() {
        return (
            <div className='homepage'>
                <Mid></Mid>
                <Brand></Brand>
                <Fun></Fun>
                <Pro></Pro>
            </div>
        )
    }
}
