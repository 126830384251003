import  React,{Component}from 'react'

import Nav from '../../components/Nav/Nav'
import Banner from '../../components/Banner/Banner'
import HomePage from '../homepage/index'
import Footer from '../../components/Footer/Footer'
import Application from '../application/index'
import About from '../about/index'
import Openplt from '../openplt/index'

import { Route, Switch, Redirect } from 'react-router-dom'
class Main extends Component{
  render(){
    return (
      <div className='hmain' style={{minWidth:'1200px'}}>
          <Nav></Nav>
          <Banner></Banner>
          <Switch>
              <Route  path="/home" component={HomePage} />
              <Route  path="/application" component={Application}/>
              <Route  path="/about" component={About}/>
              <Route  path="/openplt" component={Openplt}/>
              <Redirect to='/home'/>
          </Switch>
          <Footer></Footer>
      </div>
    )
  }
}
export default Main
