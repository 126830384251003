import React, { Component } from 'react';

import './Brand.css'
import img1 from '../../assets/brand01.png'
import img2 from '../../assets/brand02.png'
import img3 from '../../assets/brand03.png'
import img4 from '../../assets/brand04.png'

class Brand extends Component {
    render() {
        let brandlist =[
            {id:0,title:'方案成熟',img:img1,con:'高稳定性，系统在正常的使用过程中能够稳定、可靠地进行业务数据处理'},
            {id:1,title:'简洁高效',img:img2,con:'简洁的程序设计，高效的识别验真，让用户体验更快更便携的票据管理'},
            {id:2,title:'可拓展易使用',img:img3,con:'具有高度的灵活性和可扩展性，可根据需求改变及调整，满足新的应用需求'},
            {id:3,title:'高效运营',img:img4,con:'通过OCR能够帮助企业快速实现发票识别，最大化提高运营效率。'},
        ]
        return (
            <div className='brand'>
                <div className='brand-top'>品牌优势</div>
                <div className='line'></div>
                <div className='brand-box'>
                {
                        brandlist.map((item,index)=>{
                            return (
                                <div key={index} className='brand-item'>
                                    <div className='img' style={{display:'inline-block'}}>
                                        <img src={item.img} alt=''/>
                                    </div>
                                    <div className='item-top'>
                                        <span>{item.title}</span>
                                        <span style={{fontSize:'12px'}}>{item.con}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default Brand;