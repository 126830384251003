import React, { Component, Fragment } from 'react'
import './css/def.css'
import '../../style/bootstrap.css'
import './css/fonts.css'
import './css/index.css'

import img1 from '../../images/4028aa877978ee2a01798d60845b050e.jpg'
import img2 from '../../images/4028aa877978ee2a01798d5773810509.jpg'
import img3 from '../../images/4028aa877978ee2a01798d82f60a051c.jpg'
import img4 from '../../images/4028aa877978ee2a01798d83e172051d.png'
import img5 from '../../images/4028aa877978ee2a01798d7a9e860517.jpg'
import img6 from '../../images/4028aa877978ee2a01798d982e50052f.jpg'
import img7 from '../../images/4028aa877978ee2a01798defc7f105fa.png'
import img8 from '../../images/4028aa877978ee2a01798e438d8106d6.png'
import img9 from '../../images/4028aa877978ee2a01798e17b5870655.png'
import img10 from '../../images/4028aa877978ee2a01798e4863f506d7.jpg'

export default class Application extends Component {
    render() {
        return (
            <Fragment>
                <div className="customlayout-xroot comp-root custom-layout-relative s-101103-0"
                    id="app-101103" idn-101103="" style={{ width: '100%', background: '#fff',minWidth:'1180px' }}>
                    <div className="customlayout-xroot comp-root custom-layout-relative header s-101103-1"
                    >
                        应用列表
                    </div>
                    <div style={ { display: 'flex',justifyContent: 'center' } } className="customlayout-xroot comp-root custom-layout-relative s-101103-2 ">
                        <div className="customlayout-xroot comp-root custom-layout-relative content-mi s-101103-3"
                            _h-align="true">
                            <div className="customlayout-xroot comp-root custom-layout-relative main-item s-101103-4">
                                <div className="customlayout-xroot comp-root custom-layout-relative main-item-content s-101103-5">
                                    <div className="customlayout-xroot comp-root custom-layout-relative main-item-content-header s-101103-6">
                                        <div className="image-xroot comp-root s-101103-7">
                                            <img className="image-content image-empty" src={ img1 } alt='OCR发票识别' />
                                        </div>
                                    </div>
                                    <div className="customlayout-xroot comp-root custom-layout-relative main-item-content-body s-101103-8">
                                        <div className="customlayout-xroot comp-root custom-layout-relative header-text s-101103-9"
                                        >OCR发票识别
                                        </div>
                                        <div className="customlayout-xroot comp-root custom-layout-relative body-text s-101103-10"
                                        > 票联易提供领先的OCR识别、查验与认证服务，为企业建设业财税数据融合的“发票池
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="customlayout-xroot comp-root custom-layout-relative main-item s-101103-11">
                                <div className="customlayout-xroot comp-root custom-layout-relative main-item-content s-101103-12">
                                    <div className="customlayout-xroot comp-root custom-layout-relative main-item-content-header s-101103-13">
                                        <div className="image-xroot comp-root s-101103-14">
                                            <img className="image-content image-empty" alt='发票上传' src={img2} />
                                        </div>
                                    </div>
                                    <div className="customlayout-xroot comp-root custom-layout-relative main-item-content-body s-101103-15">
                                        <div className="customlayout-xroot comp-root custom-layout-relative header-text s-101103-16"
                                            >发票上传
                                        </div>
                                        <div className="customlayout-xroot comp-root custom-layout-relative body-text s-101103-17"
                                        >支持影像上传，自动识别影像中的发票，支持一张影像多张发票，通过相册选择图片、拍照上传
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="customlayout-xroot comp-root custom-layout-relative main-item s-101103-18">
                                <div className="customlayout-xroot comp-root custom-layout-relative main-item-content s-101103-19">
                                    <div className="customlayout-xroot comp-root custom-layout-relative main-item-content-header s-101103-20">
                                        <div className="image-xroot comp-root s-101103-21">
                                            <img className="image-content image-empty" alt='发票验真' src={img3} />
                                        </div>
                                    </div>
                                    <div className="customlayout-xroot comp-root custom-layout-relative main-item-content-body s-101103-22">
                                        <div className="customlayout-xroot comp-root custom-layout-relative header-text s-101103-23"
                                        >发票验真
                                        </div>
                                        <div className="customlayout-xroot comp-root custom-layout-relative body-text s-101103-24"
                                        >发票验真，对上传的特定类型发票进行验真操作，可以实时查看验真状态，保证发票真实性
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="customlayout-xroot comp-root custom-layout-relative main-item s-101103-25">
                                <div className="customlayout-xroot comp-root custom-layout-relative main-item-content s-101103-26">
                                    <div className="customlayout-xroot comp-root custom-layout-relative main-item-content-header s-101103-27">
                                        <div className="image-xroot comp-root s-101103-28">
                                            <img className="image-content image-empty" alt='发票信息展示' src={img4} />
                                        </div>
                                    </div>
                                    <div className="customlayout-xroot comp-root custom-layout-relative main-item-content-body s-101103-29">
                                        <div className="customlayout-xroot comp-root custom-layout-relative header-text s-101103-30"
                                        >发票信息展示
                                        </div>
                                        <div className="customlayout-xroot comp-root custom-layout-relative body-text s-101103-31"
                                        >展示发票识别的结果，将发票的所有信息都展示出来，更加直观的了解发票内容
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="customlayout-xroot comp-root custom-layout-relative main-item s-101103-32">
                                <div className="customlayout-xroot comp-root custom-layout-relative main-item-content s-101103-33">
                                    <div className="customlayout-xroot comp-root custom-layout-relative main-item-content-header s-101103-34">
                                        <div className="image-xroot comp-root s-101103-35">
                                            <img className="image-content image-empty" alt='统计发票数据' src={img5} />
                                        </div>
                                    </div>
                                    <div className="customlayout-xroot comp-root custom-layout-relative main-item-content-body s-101103-36">
                                        <div className="customlayout-xroot comp-root custom-layout-relative header-text s-101103-37"
                                        >统计发票数据
                                        </div>
                                        <div className="customlayout-xroot comp-root custom-layout-relative body-text s-101103-38"
                                        >统计发票的数量，及报销、验真状态，发票的总金额，有效减少人工核算工作量
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="customlayout-xroot comp-root custom-layout-relative main-item s-101103-39">
                                <div className="customlayout-xroot comp-root custom-layout-relative main-item-content s-101103-40">
                                    <div className="customlayout-xroot comp-root custom-layout-relative main-item-content-header s-101103-41">
                                        <div className="image-xroot comp-root s-101103-42">
                                            <img className="image-content image-empty" alt= '微信卡包' src={img6} />
                                        </div>
                                    </div>
                                    <div className="customlayout-xroot comp-root custom-layout-relative main-item-content-body s-101103-43">
                                        <div className="customlayout-xroot comp-root custom-layout-relative header-text s-101103-44"
                                        >微信卡包
                                        </div>
                                        <div className="customlayout-xroot comp-root custom-layout-relative body-text s-101103-45"
                                        >支持从微信卡券上传发票，快速的从微信卡券读取发票信息，更加方便快捷
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="customlayout-xroot comp-root custom-layout-relative footer s-101103-46"
                    >应用场景
                    </div>
                    <div className="customlayout-xroot comp-root custom-layout-relative footer-content s-101103-47">
                        <div className="customlayout-xroot comp-root custom-layout-absolute des s-101103-48"
                            _h-align="true">
                            <div className="customlayout-xroot comp-root custom-layout-relative des-left s-101103-49">
                                <div className="customlayout-xroot comp-root custom-layout-relative footer-desc-left s-101103-50"
                                >财税报销
                                </div>
                                <div className="customlayout-xroot comp-root custom-layout-relative footer-desc-left-header s-101103-51"
                                >自动识别录入各类税务发票、交通票据，应用于企业税务核算及内部报销场景，有效减少人工核算工作量，降低人力成本，实现财税报销的自动化
                                </div>
                            </div>
                            <div className="customlayout-xroot comp-root custom-layout-relative desc-right s-101103-52">
                                <div className="image-xroot comp-root s-101103-53">
                                    <img className="image-content image-empty" alt='财税报销' src={ img7 } />
                                </div>
                            </div>
                        </div>
                        <div className="customlayout-xroot comp-root custom-layout-absolute s-101103-54">
                        </div>
                        <div className="customlayout-xroot comp-root custom-layout-absolute s-101103-55">
                        </div>
                    </div>
                    <div className="customlayout-xroot comp-root custom-layout-relative advantage-title s-101103-56"
                    >特色优势
                    </div>
                    <div className="customlayout-xroot comp-root custom-layout-absolute advatange-main s-101103-57" style={{ marginBottom: '80px' }}>
                        <div className="customlayout-xroot comp-root custom-layout-absolute advantage-content s-101103-58"
                            _h-align="true">
                            <div className="customlayout-xroot comp-root custom-layout-absolute advantage-item s-101103-59">
                                <div className="customlayout-xroot comp-root custom-layout-absolute ad-item s-101103-60">
                                    <div className="customlayout-xroot comp-root custom-layout-absolute ad-right s-101103-61">
                                        <div className="customlayout-xroot comp-root custom-layout-absolute icon-right-content s-101103-62"
                                        >字段齐全
                                        </div>
                                        <div className="customlayout-xroot comp-root custom-layout-absolute icon-right-content-bottom s-101103-63"
                                        >可快速结构化识别常用票据的所有字段，信息丰富，选择多样，充分满足各类业务场景需求
                                        </div>
                                        <div className="image-xroot comp-root">
                                            <img className="image-content image-empty icon1" alt='特色优势' src={img8} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="customlayout-xroot comp-root custom-layout-absolute advantage-item s-101103-64">
                                <div className="customlayout-xroot comp-root custom-layout-absolute ad-item s-101103-65">
                                    <div className="customlayout-xroot comp-root custom-layout-absolute ad-right s-101103-66">
                                        <div className="customlayout-xroot comp-root custom-layout-absolute icon-right-content s-101103-67"
                                        >效果领先
                                        </div>
                                        <div className="customlayout-xroot comp-root custom-layout-absolute icon-right-content-bottom s-101103-68"
                                        >针对各类票据特定的字体，关键字段准确率高达98%，其中增值税发票、出租车票四要素准确率高达99.9%
                                        </div>
                                        <div className="image-xroot comp-root">
                                            <img className="image-content image-empty icon1" alt = '特色优势' src={img9}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="customlayout-xroot comp-root custom-layout-absolute advantage-item s-101103-69">
                                <div className="customlayout-xroot comp-root custom-layout-absolute ad-item s-101103-70">
                                    <div className="customlayout-xroot comp-root custom-layout-absolute ad-right s-101103-71">
                                        <div className="customlayout-xroot comp-root custom-layout-absolute icon-right-content s-101103-72"
                                        >支持微信卡券
                                        </div>
                                        <div className="customlayout-xroot comp-root custom-layout-absolute icon-right-content-bottom s-101103-73"
                                        >票联易可以拉取微信卡券的票据信息，可快速完成发票识别，方便快捷
                                        </div>
                                        <div className="image-xroot comp-root">
                                            <img className="image-content image-empty icon1"  alt = '特色优势' src={img10} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
