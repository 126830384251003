import React, { Component } from 'react'

import './css/index.css'
import './css/def.css'
import './css/fonts.css'
// import './css/bootstrap.css'

export default class openplt extends Component {
    render() {
        return (
            <div className="customlayout-xroot comp-root custom-layout-relative s-101105-0" id="app-101105" style={{marginBottom:'50px'}} idn-101105="">
                <div className="customlayout-xroot comp-root custom-layout-relative mauto s-101105-1">
                    <span l="0" className="textshow-xroot comp-root textshow-singlerow s-101105-2">
                        API列表
                    </span>
                </div>
                <div className="customlayout-xroot comp-root custom-layout-relative mauto s-101105-3">
                    <div className="customlayout-xroot comp-root custom-layout-absolute s-101105-4">
                        <span l="2" className="textshow-xroot comp-root textshow-autocontent bor s-101105-5">
                            api列表
                        </span>
                        <span l="2" className="textshow-xroot comp-root textshow-autocontent bor s-101105-6">
                            描述
                        </span>
                        <span l="2" className="textshow-xroot comp-root textshow-autocontent s-101105-7">
                            版本号
                        </span>
                    </div>
                    <div className="customlayout-xroot comp-root custom-layout-relative s-101105-8">
                        <div className="customlayout-xroot comp-root custom-layout-relative s-101105-9">
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-10">
                                http://ip:port/ply/api/queryInfo/getYXFPData
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-11">
                                http://ip:port/ply/api/org/bindUserBetOrg
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-12">
                                http://ip:port/ply/api/yxOpe/updateYXStatus
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-13">
                                http://ip:port/ply/api/compress/compre
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-14">
                                http://ip:port/ply/api/fpInfo/getFPDatas
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-15">
                                http://ip:port/ply/api/bindIdentity/code
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow s-101105-16">
                                http://ip:port/ply/api/bindIdentity/identity
                            </span>
                        </div>
                        <div className="customlayout-xroot comp-root custom-layout-relative s-101105-17">
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-18">
                                查询影像/发票数据
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-19">
                                绑定用户和组织机构
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-20">
                                更新影像状态
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-21">
                                图片下载
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-22">
                                获取发票结构化数据
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-23">
                                绑定获取验证码
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow s-101105-24">
                                绑定认证
                            </span>
                        </div>
                        <div className="customlayout-xroot comp-root custom-layout-relative s-101105-25">
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-26">
                                1.0.0
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-27">
                                1.0.0
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-28">
                                1.0.0
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-29">
                                1.0.0
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-30">
                                1.0.0
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow bot s-101105-31">
                                1.0.0
                            </span>
                            <span l="0" className="textshow-xroot comp-root textshow-singlerow s-101105-32">
                                1.0.0
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
