/*
 * @Author: xcc
 * @Date: 2021-06-01 09:56:38
 * @LastEditors: xcc
 * @LastEditTime: 2021-06-01 15:05:53
 * @Description: 入口
 */
import React, { Fragment } from 'react'

import Main from './pages/main'
import { Route, Switch } from 'react-router-dom'
function App() {
  return (
    <Fragment>
        <Switch>
          <Route path="/" component={Main} />
        </Switch>
    </Fragment>
  );
}

export default App;
