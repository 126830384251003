import React, { Component } from 'react'

import './Pro.css'

export default class Pro extends Component {
    render() {
        let prolist =[
            {id:0,name:'小程序',fun1:'永久免费',fun2:'专业团队维护',fun3:'随时随地上传发票',fun4:'先进的OCR验证技术',fun5:'完整的专业帮助文档'},
            {id:1,name:'票联易后台',fun1:'影像统计',fun2:'用户、发票数在线查看',fun3:'24h专属服务',fun4:'验真、日志记录统一管理',fun5:'专业人员维护'}
        ]
        return (
            <div className='pro'>
                <div className='pro-top'>产品系列</div>
                <div className='line'></div>
                <div className='pro-box'>
                    {
                        prolist.map((item,index)=>{
                            return (
                                <div key={index} className='pro-item'>
                                    <p>{item.name}</p>
                                    <p>{item.fun1}</p>
                                    <p>{item.fun2}</p>
                                    <p>{item.fun3}</p>
                                    <p>{item.fun4}</p>
                                    <p>{item.fun5}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}
