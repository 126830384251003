import React, { Component } from 'react';
import './Banner.css'
import bannerimg from '../../assets/banner_home.jpg'

class Banner extends Component {
    render() {
        return (
            <div className='banner'>
                <img src={bannerimg} alt=""/>
            </div>
        );
    }
}

export default Banner;
