import React, { Component } from 'react'
import './Fun.css'

import img1 from '../../assets/fun01.png'
import img2 from '../../assets/fun02.png'
import img3 from '../../assets/fun03.png'
import img4 from '../../assets/fun04.png'

export default class Fun extends Component {
    render() {
        let funlist =[
            {id:0,name:'发票实时统计',img:img1},
            {id:1,name:'发票上传验真',img:img2},
            {id:2,name:'发票报销管理',img:img3},
            {id:3,name:'发票OCR识别',img:img4},
        ]
        return (
            <div className='fun'>
                <div className='fun-top'>四大功能</div>
                <div className='line'></div>
                <div className='fun-box'>
                {
                        funlist.map((item,index)=>{
                            return (
                                <div key={index} className='fun-item'>
                                    <div className='fun-img' style={{display:'inline-block'}}>
                                        <img src={item.img} alt=''/>
                                    </div>
                                    <div className='fun-item-name'>
                                        <span>{item.name}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}
