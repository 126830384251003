import React, { Component } from 'react';
import './Mid.css'
import img1 from '../../assets/mid01.png'
import img2 from '../../assets/mid02.png'
import img3 from '../../assets/mid03.png'
import img4 from '../../assets/mid04.png'

class Mid extends Component {
    render() {
        let midlist = [
            {id:0,title:'数据统计',con:'发票数据同步更新',img:img1},
            {id:1,title:'发票上传',con:'拍照、相册选择，功能齐全',img:img2},
            {id:2,title:'发票详情',con:'各类发票信息，一应俱全',img:img3},
            {id:3,title:'登录注册',con:'一键登录，方便快捷',img:img4}
        ]
        return (
            <div className='mid'>
                <div className='mid-list'>
                    {
                        midlist.map((item,index)=>{
                            return (
                                <div key={index} className='mid-item'>
                                    <img src={item.img} alt=''/>
                                    <div className='item-top'>
                                        <span>{item.title}</span>
                                        <span style={{fontSize:'12px'}}>{item.con}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default Mid;