import React,{Component} from 'react'
import { withRouter } from 'react-router-dom'
import './Nav.css'
import logo1 from '../../assets/logo.png'
// import { browserHistory } from 'react-router';

class Nav extends Component {
    state = {
        isBg: false,
        isBig: 0
    }
    render() {
        let navlist = [
            {id:0,name:'首页'},
            {id:1,name:'应用'},
            {id:2,name:'关于我们'},
            {id:3,name:'开放平台'}
            // {id:4,name:'识别说明'}
        ]
        return(
            <div className={this.state.isBg ? 'nav navScr' : 'nav'}>
                <div className='nav-left' onClick={()=>{this.goLink(0)}}>
                    <img src={logo1} alt="票联易"/>
                    <span>票联易</span>
                </div>
                <div className='nav-list'>
                    {
                        navlist.map((item,index)=>{
                            return (
                                <div className={this.state.isBig === index ? 'big' : ''} key={index} onClick={()=>{this.goLink(index)}}>{item.name}</div>
                             )
                         })
                    }
                </div>
                <div className='nav-right' style={{float:'right'}}>
                    <div onClick={()=>{this.goLink('login')}}>后台管理</div>
                </div>
            </div>
        )
    }
    goLink = (param) => {
        if(param === 0){
            this.props.history.push('/home')
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }else if(param ===1){
            this.props.history.push('/application')
            document.body.scrollTop = document.documentElement.scrollTop = 590;
        }else if(param ===2){
            this.props.history.push('/about')
            document.body.scrollTop = document.documentElement.scrollTop = 590;
        }else if(param ===3){
            this.props.history.push('/openplt')
            document.body.scrollTop = document.documentElement.scrollTop = 590;
        }else if(param ===4){
            // window.open("http://work.pansoft.com/api-doc/project.do#/ffff-1605836893845-192168248247-0006/interface/list/ffff-1605837277884-192168248247-0006");
        }else if(param === 'login'){
            window.open('http://ply.pansoft.com/ply/login/')
        }

        console.log(this.props.history.location.pathname)
        let pathname = this.props.history.location.pathname

        if(pathname === '/home'){
            this.setState({
                isBig: 0
            })
        }else if(pathname === '/application'){
            this.setState({
                isBig: 1
            })
        }else if(pathname === '/about'){
            this.setState({
                isBig: 2
            })
        }else if(pathname === '/openplt'){
            this.setState({
                isBig: 3
            })
        }
       


    };
    componentWillUpdate(){

        // console.log(this.props.location.pathname)
        
        
    }
    componentDidMount () {
        window.addEventListener('scroll', () => {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop > 0) {
                this.setState({
                    isBg: true
                })
            } else {
                this.setState({
                    isBg: false
                })
            }
        })
    }
}

export default withRouter(Nav)
